<template>
  <v-dialog v-model="warningDialog" width="650">
    <v-card>
      <v-toolbar color="warning" dark flat>
        <v-icon @click="warningDialog = false">mdi-close</v-icon>
        <v-toolbar-title class="flex text-center">
          Warnung
        </v-toolbar-title>
      </v-toolbar>
      <v-container class="pa-4">
        <v-row class="mx-2">
          <v-col cols="12">
            {{ warnMessage }}
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="d-flex flex-column "
            cols="colsPerButton"
            v-for="action in actions"
            :key="action.action"
          >
            <v-btn
              outlined
              class="warning--text"
              @click="warningActionPerformed(action.action)"
            >
              <v-icon class="mr-1">{{ action.icon }}</v-icon>
              {{ action.text }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    warningDialog: false,
    warnMessage: '',
    actions: [],
    colsPerButton: 0,
    additionalData: []
  }),
  methods: {
    open(warnMessage, actions, additionalData = []) {
      this.warnMessage = warnMessage
      this.actions = actions
      this.warningDialog = true
      this.colsPerButton = Math.floor(12 / actions.length)
      this.additionalData = additionalData
    },
    warningActionPerformed(action) {
      this.$emit('warningActionPerformed', action)
      this.warningDialog = false
    }
  }
}
</script>
