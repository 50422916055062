<template>
  <v-container class="pa-0">
    <v-card outlined :style="cardStyle" :rounded="rounded">
      <v-container v-if="editMode">
        <v-row>
          <v-col cols="12" sm="4" md="4" lg="3" xl="3">
            <v-btn-toggle dense multiple>
              <button
                type="button"
                class="v-btn v-btn--flat v-btn--icon theme--light v-size--default v-btn--round grey lighten-3"
                :class="{
                  'v-item--active v-btn--active': editor.isActive('bold')
                }"
                @click="
                  editor
                    .chain()
                    .focus()
                    .toggleBold()
                    .run()
                "
              >
                <v-icon>mdi-format-bold</v-icon>
              </button>
              <button
                type="button"
                class="v-btn v-btn--flat v-btn--icon theme--light v-size--default v-btn--round grey lighten-3"
                :class="{
                  'v-item--active v-btn--active': editor.isActive('italic')
                }"
                @click="
                  editor
                    .chain()
                    .focus()
                    .toggleItalic()
                    .run()
                "
              >
                <v-icon>mdi-format-italic</v-icon>
              </button>
              <button
                type="button"
                class="v-btn v-btn--flat v-btn--icon theme--light v-size--default v-btn--round grey lighten-3"
                :class="{
                  'v-item--active v-btn--active': editor.isActive('strike')
                }"
                @click="
                  editor
                    .chain()
                    .focus()
                    .toggleStrike()
                    .run()
                "
              >
                <v-icon>mdi-format-strikethrough-variant</v-icon>
              </button>
              <button
                type="button"
                class="v-btn v-btn--flat v-btn--icon theme--light v-size--default v-btn--round grey lighten-3"
                :class="{
                  'v-item--active v-btn--active': editor.isActive('code')
                }"
                @click="
                  editor
                    .chain()
                    .focus()
                    .toggleCode()
                    .run()
                "
              >
                <v-icon>mdi-code-tags</v-icon>
              </button>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="3" xl="3">
            <v-btn-toggle dense>
              <button
                type="button"
                class="v-btn v-btn--flat v-btn--icon theme--light v-size--default v-btn--round grey lighten-3"
                :class="{
                  'v-item--active v-btn--active': editor.isActive('heading', {
                    level: 1
                  })
                }"
                @click="
                  editor
                    .chain()
                    .focus()
                    .toggleHeading({ level: 1 })
                    .run()
                "
              >
                <v-icon>mdi-format-header-1</v-icon>
              </button>
              <button
                type="button"
                class="v-btn v-btn--flat v-btn--icon theme--light v-size--default v-btn--round grey lighten-3"
                :class="{
                  'v-item--active v-btn--active': editor.isActive('heading', {
                    level: 2
                  })
                }"
                @click="
                  editor
                    .chain()
                    .focus()
                    .toggleHeading({ level: 2 })
                    .run()
                "
              >
                <v-icon>mdi-format-header-2</v-icon>
              </button>
              <button
                type="button"
                class="v-btn v-btn--flat v-btn--icon theme--light v-size--default v-btn--round grey lighten-3"
                :class="{
                  'v-item--active v-btn--active': editor.isActive('heading', {
                    level: 3
                  })
                }"
                @click="
                  editor
                    .chain()
                    .focus()
                    .toggleHeading({ level: 3 })
                    .run()
                "
              >
                <v-icon>mdi-format-header-3</v-icon>
              </button>
              <button
                type="button"
                class="v-btn v-btn--flat v-btn--icon theme--light v-size--default v-btn--round grey lighten-3"
                :class="{
                  'v-item--active v-btn--active': editor.isActive('paragraph')
                }"
                @click="
                  editor
                    .chain()
                    .focus()
                    .setParagraph()
                    .run()
                "
              >
                <v-icon>mdi-format-paragraph</v-icon>
              </button>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="3" xl="3">
            <v-btn-toggle dense>
              <button
                type="button"
                class="v-btn v-btn--flat v-btn--icon theme--light v-size--default v-btn--round grey lighten-3"
                :class="{
                  'v-item--active v-btn--active': editor.isActive('bulletList')
                }"
                @click="
                  editor
                    .chain()
                    .focus()
                    .toggleBulletList()
                    .run()
                "
              >
                <v-icon>
                  mdi-format-list-bulleted
                </v-icon>
              </button>
              <button
                type="button"
                class="v-btn v-btn--flat v-btn--icon theme--light v-size--default v-btn--round grey lighten-3"
                :class="{
                  'v-item--active v-btn--active': editor.isActive('orderedList')
                }"
                @click="
                  editor
                    .chain()
                    .focus()
                    .toggleOrderedList()
                    .run()
                "
              >
                <v-icon>
                  mdi-format-list-numbered
                </v-icon>
              </button>
              <button
                type="button"
                class="v-btn v-btn--flat v-btn--icon theme--light v-size--default v-btn--round grey lighten-3"
                :class="{
                  'v-item--active v-btn--active': editor.isActive('taskList')
                }"
                @click="
                  editor
                    .chain()
                    .focus()
                    .toggleTaskList()
                    .run()
                "
              >
                <v-icon>
                  mdi-format-list-checkbox
                </v-icon>
              </button>
              <button
                type="button"
                class="v-btn v-btn--flat v-btn--icon theme--light v-size--default v-btn--round grey lighten-3"
                :class="{
                  'v-item--active v-btn--active': editor.isActive('blockquote')
                }"
                @click="
                  editor
                    .chain()
                    .focus()
                    .toggleBlockquote()
                    .run()
                "
              >
                <v-icon>
                  mdi-format-quote-open
                </v-icon>
              </button>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-container>
      <v-divider v-if="editMode"></v-divider>

      <v-row class="pa-3">
        <v-col cols="12">
          <EditorContent :editor="editor" />
        </v-col>
      </v-row>
      <v-card-actions v-if="optionalEditing">
        <div v-if="editMode && editingCancel">
          <v-btn small color="red" class="mx-2" @click="cancel()">
            Cancel
          </v-btn>
          <v-btn small color="green" class="mx-2" @click="save()">
            Save
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          small
          icon
          tile
          outlined
          elevation="0"
          class="rounded-sm"
          v-model="editMode"
          @click="toggleEdit()"
        >
          <v-icon small>
            mdi-pencil
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Placeholder from '@tiptap/extension-placeholder'

export default {
  components: {
    EditorContent
  },
  props: {
    text: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
    optionalEditing: {
      type: Boolean,
      required: false,
      default: false
    },
    editingCancel: {
      type: Boolean,
      required: false,
      default: false
    },
    saveId: {
      type: Number,
      required: false,
      default: null
    },
    borderThickness: {
      type: Number,
      required: false,
      default: 1
    },
    borderStyle: {
      type: String,
      required: false,
      default: 'solid #9E9E9E'
    },
    rounded: {
      type: String,
      required: false,
      default: 'md'
    }
  },
  data: () => ({
    editor: null,
    editMode: false,
    oldText: ''
  }),
  computed: {
    border() {
      return 'border: ' + this.borderThickness + 'px ' + this.borderStyle
    },
    cardStyle() {
      return this.border
    }
  },
  methods: {
    toggleEdit() {
      this.editMode = !this.editMode
      this.editor.setEditable(this.editMode)
      this.oldText = this.editor.getHTML()
    },
    save() {
      var text = this.editor.getHTML()
      var returnValue =
        this.saveId == null ? text : { id: this.saveId, text: text }
      this.$emit('saved', returnValue)
      this.toggleEdit()
    },
    cancel() {
      this.editor.commands.setContent(this.oldText)
      this.toggleEdit()
    }
  },
  watch: {
    text(newValue) {
      this.editor.commands.setContent(newValue)
    }
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        TaskList,
        TaskItem.configure({
          nested: true
        }),
        Placeholder.configure({
          placeholder: this.placeholder,
          emptyEditorClass: 'is-editor-empty'
        })
      ],
      content: this.text,
      injectCSS: true,
      editable: false
    })
    if (this.editable) {
      if (!this.optionalEditing) {
        this.editMode = true
      }
      this.editor.setEditable(this.editMode)
    }
  }
}
</script>

<style lang="scss">
.tiptap {
  height: 100%;
  overflow-y: auto;
}
.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
.tiptap textarea:focus {
  border: 0px;
}
.ProseMirror:focus {
  border-radius: 0px;
  outline: none;
}
.tiptap {
  > * + * {
    margin-top: 0.75em;
  }

  ul[data-type='taskList'] {
    list-style: none;

    p {
      margin: 0;
    }

    li {
      display: flex;

      > label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
        user-select: none;
      }

      > div {
        flex: 1 1 auto;
      }

      ul li,
      ol li {
        display: list-item;
      }

      ul[data-type='taskList'] > li {
        display: flex;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  blockquote {
    margin-left: 1rem;
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }
}
</style>
